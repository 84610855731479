<!-- 组件说明 -->
<template>
    <div class="home_work" v-loading="loading">
        <div class="ls_flexalign_center search_part">
            <el-select v-model="params.status" size="medium">
                <el-option v-for="item in statusOptions" :key="item.label" :label="item.label" :value="item.value" />
            </el-select>
            <el-select v-model="params.groupId" placeholder="全部标签" size="medium">
                <el-option v-for="dict in courseList" :key="dict.id" :label="dict.name" :value="dict.id" />
            </el-select>
            <el-input v-model.trim="params.schoolworkName" size="medium" placeholder="请输入作业名称"></el-input>

            <el-button size="medium" plain @click="(params.pageIndex = 1), getList()">
                查询
            </el-button>
            <div class="ls_flex_1"></div>
            <el-button size="medium" type="success" @click="toEditHomework()">
                创建作业
            </el-button>
        </div>
        <div class="work_list" v-if="total > 0">
            <el-table ref="multipleTable" :data="workList" highlight-current-row
                :header-cell-style="{ background: '#FAFAFA' }" style="width: 100%">
                <el-table-column label="作业名称" min-width="180">
                    <template slot-scope="scope">
                        <span :title="scope.row.schoolworkName" class="written">
                            {{ scope.row.schoolworkName }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="布置范围" min-width="180">
                    <template slot-scope="scope">
                        <span :title="scope.row.groupNames.join(',')">
                            {{ scope.row.groupNames | classRange }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="发布时间" min-width="100">
                    <template slot-scope="scope">
                        <span>
                            {{
                                scope.row.publishTime | formatDate("yyyy-MM-dd")
                            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="截止时间" min-width="120">
                    <template slot-scope="scope">
                        <span>
                            {{ scope.row.submitEndTime }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" min-width="70">
                    <template slot-scope="scope">
                        <span :class="scope.row.status == 1 ? 'main_color' : ''">
                            {{
                                scope.row.status == 0
                                ? "未发布"
                                : scope.row.status == 1
                                    ? "已发布"
                                    : "已批阅"
                            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="220px">
                    <template slot-scope="scope">
                        <div class="opt_part">
                            <span v-if="scope.row.status == 0" @click="startTask(scope.row)">
                                发布作业
                            </span>
                            <span v-if="scope.row.status == 0" @click="delTask(scope.row)">
                                删除作业
                            </span>
                            <span v-if="scope.row.status == 0" @click="toEditHomework(scope.row.id)">
                                编辑作业
                            </span>

                            <span v-if="scope.row.status == 1" @click="backTask(scope.row)">
                                撤回作业
                            </span>
                            <span v-if="scope.row.status == 1" @click="toCorrect(scope.row)">
                                批阅作业
                            </span>

                            <span v-if="scope.row.status == 2" @click="toCorrect(scope.row)">查看作业</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="total == 0 && !loading" class="no_data">
            <div class="empty">
                <i class="iconfont icon-queshengye_zanwushuju"></i>
            </div>
            <p class="tc">暂无数据</p>
        </div>
        <div class="page_part">
            <el-pagination v-show="total > 0" :current-page="params.pageIndex" :page-sizes="[10, 15, 20, 25]"
                :page-size="params.pageSize" :total="total" background layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script>

export default {
    data () {
        return {
            params: {
                schoolworkName: "",
                groupId: "",
                status: "",

                pageIndex: 1,
                pageSize: 10,
            },
            queryParams: {
                title: "",
                desc: "",
                endTime: "",
                materialIds: "",
                groupIds: [],
            },
            statusOptions: [
                { value: "", label: "全部" },
                { value: 0, label: "未发布" },
                { value: 1, label: "已发布" },
                { value: 2, label: "已批阅" },
            ],

            typeOptions: [
                {
                    name: "花卉",
                    code: "flower",
                },
                {
                    name: "器皿",
                    code: "cover",
                },
            ],
            course: "",
            courseList: [],
            workList: [],
            total: null,
            loading: false,
            flowerList: [],
            coverList: [],
            ornamentList: [],
            materialTypeOption: [],

            undetermined: [],
            choose: [],
            materialParams: {
                type: "",
                materialType: "",
                pageIndex: 1,
                pageSize: 10,
                colorValue: "",
                endTime: "",
                florescenceId: "",
                name: "",
                startTime: "",
                status: "",
            },
            tableData: [],
            materialTotal: null,
        };
    },
    mounted () {
        this.loading = true;
        this.$ajax.post("/user/group/getList", {}).then(response => {
            this.courseList = response.data;
            this.courseList.unshift({ name: "全部标签", id: "" });
            this.getList();
        });
    },
    methods: {
        getList () {
            this.loading = true;
            this.$ajax
                .post("/schoolwork/queryList", this.params)
                .then(response => {
                    var pageTotal = Math.ceil(
                        response.total / this.params.pageSize
                    );
                    if (pageTotal == 0) {
                        this.params.pageIndex = 1;
                    }
                    if (
                        this.params.pageIndex > pageTotal &&
                        this.params.pageIndex != 1
                    ) {
                        this.params.pageIndex = pageTotal;
                        this.getList();
                    } else {
                        this.workList = response.list;
                        this.total = response.total;
                        this.loading = false;
                    }
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        // 发布
        startTask (row) {
            const h = this.$createElement;
            this.$confirm("", {
                message: h("div", null, [
                    h("i", {
                        class: "el-icon-warning",
                        style: "color:#FFAA00;font-size:24px;",
                    }),
                    h(
                        "span",
                        {
                            style: "margin-left:12px;font-size:16px;line-height:24px;vertical-align: top;color:#333333;font-family: MicrosoftYaHeiUI;",
                        },
                        "确认发布该作业?"
                    ),
                    h(
                        "p",
                        {
                            style: "font-size: 12px;color: #999999;margin-left:34px;",
                        },
                        " "
                    ),
                ]),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
                showClose: false,
                customClass: "custom-dialog w350",
            }).then(() => {
                this.$ajax
                    .get("/schoolwork/publish", { params: { id: row.id } })
                    .then(res => {
                        this.$message({
                            message: "发布成功",
                            type: "success",
                        });
                        this.getList();
                    })
                    .catch(err => { });
            });
        },
        // 撤回
        backTask (row) {
            const h = this.$createElement;
            this.$confirm("", {
                message: h("div", null, [
                    h("i", {
                        class: "el-icon-warning",
                        style: "color:#FFAA00;font-size:24px;",
                    }),
                    h(
                        "span",
                        {
                            style: "margin-left:12px;font-size:16px;line-height:24px;vertical-align: top;color:#333333;font-family: MicrosoftYaHeiUI;",
                        },
                        "确认撤回作业？"
                    ),
                    h(
                        "p",
                        {
                            style: "font-size: 12px;color: #999999;margin-left:34px;",
                        },
                        "撤回后学生将无法看到该作业"
                    ),
                ]),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
                showClose: false,
                customClass: "custom-dialog w350",
            }).then(() => {
                this.$ajax
                    .get("/schoolwork/revocation", { params: { id: row.id } })
                    .then(res => {
                        this.$message({
                            message: "撤回成功",
                            type: "success",
                        });
                        this.getList();
                    })
                    .catch(err => { });
            });
        },
        // 删除
        delTask (row) {
            const h = this.$createElement;
            this.$confirm("", {
                message: h("div", null, [
                    h("i", {
                        class: "el-icon-warning",
                        style: "color:#FFAA00;font-size:24px;",
                    }),
                    h(
                        "span",
                        {
                            style: "margin-left:12px;font-size:16px;line-height:24px;vertical-align: top;color:#333333;font-family: MicrosoftYaHeiUI;",
                        },
                        "确认删除作业？"
                    ),
                    h(
                        "p",
                        {
                            style: "font-size: 12px;color: #999999;margin-left:34px;",
                        },
                        "删除作业后将无法恢复"
                    ),
                ]),
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
                showClose: false,
                customClass: "custom-dialog",
            }).then(() => {
                this.$ajax
                    .get("/schoolwork/delete", { params: { id: row.id } })
                    .then(res => {
                        this.$message({
                            message: "删除成功",
                            type: "success",
                        });
                        this.getList();
                    });
            });
        },
        handleSizeChange (val) {
            this.params.pageIndex = 1;
            this.params.pageSize = val;
            this.getList();
        },
        handleCurrentChange (val) {
            this.params.pageIndex = val;
            this.getList();
        },
        toEditHomework (id = "") {
            this.$router.push({ path: "/homeWork/editHomeWork?id=" + id });
        },

        toCorrect (row) {
            this.$router.push({
                path: "/homework/correctList",
                query: { id: row.id, name: row.title },
            });
        },

    },
};
</script>

<style lang="scss" scoped>
.a_color {
    color: rgb(105, 191, 248);
    cursor: pointer;
}

.search_part {
    >* {
        +* {
            margin-left: 7px;
        }

        &:first-child,
        &:nth-child(2) {
            width: 120px;
        }

        &:nth-child(3) {
            width: 200px;
        }

        &:nth-child(6) {
            width: 220px;
        }
    }
}

.vert {
    vertical-align: top;
}

.home_work {
    padding: 20px 30px 0 0;

    //   padding-top: 40px;
    .select-bnt {
        display: inline-block;
        width: 86px;
        height: 32px;
        margin-right: 20px;
        cursor: pointer;
        background: #ffffff;
        // border-radius: 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        color: #999999;
        text-align: center;

        &.is-active {
            color: #f56c0b;
            border: 1px solid #f56c0b;
        }
    }
}

.work_list {
    margin-top: 20px;
    width: 100%;
    height: auto;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}

.no_data {
    text-align: center;

    .empty {
        margin-top: 60px;
        margin-bottom: 24px;

        i {
            font-size: 130px;
            color: #999;
        }
    }

    p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 139px;
    }
}

.page_part {
    height: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 10px 20px !important;
    text-align: center;
}

.ad_btn {
    display: inline-block;
    width: 96px;
    height: 96px;
    border: 1px solid #cccccc;
    cursor: pointer;
    text-align: center;

    .el-icon-plus {
        font-size: 25px;
        font-weight: bold;
        line-height: 100px;
        color: #cccccc;
    }
}

.d_class {
    .el-dialog__body {
        padding: 10px;
    }
}

.material_part {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 16px;
    text-align: center;
    vertical-align: top;

    &.c_list {
        width: 96px;
        margin-right: 11px;
    }

    .img_card {
        width: 96px;
        height: 96px;
        position: relative;
        border: 1px solid #cccccc;
        margin: auto;

        .check {
            position: absolute;
            width: 20px;
            height: 20px;
            border: 1px solid #cccccc;
            border-right: none;
            border-top: none;
            right: 0;
            top: 0;
        }

        .close {
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: #000000;
            right: 0;
            top: 0;
            cursor: pointer;

            i {
                font-weight: bold;
                font-size: 12px;
                text-align: center;
                line-height: 16px;
                color: #ffffff;
                margin-left: 2px;
            }
        }

        .a_show {
            display: none;
            line-height: 20px;
        }

        .active {
            background-color: #8bb229;
            color: #ffffff;

            .a_show {
                display: block;
            }
        }

        img {
            width: 70px;
            height: 70px;
            margin-top: 10px;
        }
    }
}

.t_btn {
    background: rgba(218, 218, 218, 0.39);
    border-radius: 4px;
    padding: 8px 24px;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid rgba(250, 250, 250, 0.39);
    cursor: pointer;

    &:hover {
        border: 1px solid #8bb229;
        color: #8bb229;
    }

    &.active {
        border: 1px solid #8bb229;
        color: #8bb229;
    }
}

.written {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}</style>
